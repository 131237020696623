import React, { Component, useContext, useState } from 'react';
import { LanguageContext } from './LanguageProvider';
import Date from './dateReader';


import ReactMarkdown from 'react-markdown'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img, { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import {SteepArrow} from '../components/usefulSVGs'


export default function News(props) {
    const context = useContext(LanguageContext)
    let [count, setCount] = useState([0, 1]);

let data = useStaticQuery(graphql`
{
    allCmbPagesJson{
        edges{         
        node{
            newsPage{
                en{
                    header
                }
                hr{
                    header
                }
                
            }
          genericItems{
                    en{
                        readMore
                        seeMore
                    }
                    hr {
                        readMore
                        seeMore
                    }
                }
            }
        }
    }
    allCmbNewsJson {
      edges {
        node {
          id
          date
          jsonId
          en {
            eventMain
          }
          hr {
            eventMain
          }
          mainImage{childImageSharp{gatsbyImageData}}
          
        }
      }
    }
  }`)

const newsItems = data.allCmbNewsJson.edges;

const more = data.allCmbPagesJson.edges[0].node



    let counter = (event) => {
        let newCount = [0, 1];
        if (event.target.name === "up") {
            newCount = [newsItems.length - 1, 0];
            if (count[1] < newsItems.length - 1) {
                newCount = [count[1], count[1] + 1]
            }
        }

        if (event.target.name === "down") {
            newCount = [newsItems.length - 1, 0]
            if (count[0] > 0) {
                newCount = [count[0] - 1, count[0]]
            }
        }
        
        setCount(newCount)
    }


    let newsWheel= (
        <section class="newsReel white">
            <ReactMarkdown includeElementIndex components={{ h1: "h2" }}>{more.newsPage[context].header}</ReactMarkdown>
            <NewsButtons changeCount={counter} name={"down"}></NewsButtons>
            <NewsHighlight data={newsItems}  count={count[0]} more ={more} />
            <NewsHighlight data={newsItems}  count={count[1]} more={more}/>
            <NewsButtons changeCount={counter} name={"up"}></NewsButtons>
        </section>
    )

    let singleItem =  (<section class="newsReel white">
                        <NewsHighlight data={newsItems}  count={count[0]} more ={more} />
                 </section>)




    return newsItems.length>=1 ? newsWheel : singleItem
}


export function NewsHighlight(props) {
    const context = useContext(LanguageContext)
    let count = props.count
    const currentNews = props.data[count]
        let image = getImage(currentNews.node.mainImage);
   let x = (
        <div className={"newsHighlight"}>
            <GatsbyImage className={"newsHighImg"} image={image} alt="" />
            <Date date={currentNews.node.date}></Date>
            <ReactMarkdown includeElementIndex components={{ h1: "h3", p: (node, index) => clipped(node) }} allowElement={(node, index) => [0, 2].indexOf(index) !== -1 ? true : false}>{currentNews.node[context].eventMain}</ReactMarkdown>
            <Link to={`/news/${currentNews.node.jsonId}`}>{props.more.genericItems[context].readMore}</Link>
        </div>
    )
    return x
}



export function clipped(props) {
    let text = props.children[0]
    let x = text.slice(0, 100) + "..."
    return <p>{x}</p>
}

export function NewsButtons(props) {
    let direction;
    props.name === "down" ? direction = "leftButton" : direction = "rightButton"
    let x = <button key={props.name} className="newsButton" name={props.name} onClick={props.changeCount}></button>
    return x
}