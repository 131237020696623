import React, { Component, useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link, useStaticQuery, graphql } from "gatsby"


import { LanguageContext } from './LanguageProvider';
import DateReader from './dateReader';
import { Calendar, LocationMarker, LanguageMarker, TimeMarker } from '../components/usefulSVGs'


export default function EventTable(props) {
    let context = useContext(LanguageContext)

    const query = useStaticQuery(graphql`
    query {allCmbPagesJson{
        edges{
        node{
            navbar{
                en{
                link3
                }
                hr{link3}
            }
        }
    }
}
        
        
        
        allCmbEventsJson(sort: {order: DESC, fields: date}, limit: 10){
         nodes {
           date
           en {
             eventMain
             eventSide
           }
           hr {
             eventMain
             eventSide
           }
           jsonId
           language
           location
           mainImage
           presenter
           time
           sideImage
         }
       }
     }
    `)

    let data = query.allCmbEventsJson.nodes;
console.log(query.allCmbPagesJson.edges)
    let title = query.allCmbPagesJson.edges[0].node.navbar[context].link3

    let now = Date.parse(new Date());

let upcoming = data.filter(event=>Date.parse(event.date) > now)
    console.log(upcoming)
    let events; 
    upcoming.length>0 ? 
        events =  upcoming.reverse().slice(0,2).map(single =>  <EventBrief data={single} />):
        events = <p style={{margin:"40px 0px"}}>{"No Events"}</p>


    let x = (
        <dl className="eventTable">
            <dt>{title}</dt>
            {events}
        </dl>
    )

    return x
}



function EventBrief({ data }) {
    let context = useContext(LanguageContext)
    let x = (
        <Link to={`../events/${data.jsonId}/`}><dd>

            <ReactMarkdown className="talkTitle" components={{ h1: "p" }} allowedElements={"h1"}>{data[context].eventMain}</ReactMarkdown>
            <p>{data.presenter}</p>
            <ul>
                <li><Calendar className={"smallEventMarker"} /> <DateReader date={data.date} /></li>
                <li><TimeMarker className={"smallEventMarker"} /><time>{data.time}</time></li>
                <li> <LocationMarker className={"smallEventMarker"} />{data.location}</li>
            </ul>
        </dd></Link>
    )
    return x
}