import React, {useState, useContext, useEffect} from 'react';
import ReactMarkdown from 'react-markdown'
import HistoryImage from '../images/bridge.jpeg';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'
import News from '../components/newsHighlightsForPage1'
import MainLayout from '../components/mainPageLayout'
import EventTable from '../components/eventTable'

import { Link, useStaticQuery, graphql } from "gatsby"
import Date from '../components/dateReader'

import Img, {getImage, StaticImage, GatsbyImage} from "gatsby-plugin-image"


import LangProvider, {LanguageConsumer, LanguageContext} from '../components/LanguageProvider'


import NewsItems from '../data/cmbNews.json'
import '../styles/cmbStyles002.css'



export default function PageWrap(props) {


const d2  = useStaticQuery(graphql`
query {
    allCmbPagesJson{
        edges{
        node{
          home{
                    en{
                      about
                      activities
                      history
                      news
              
                    header}
                    hr{
                        about
                        activities
                        history
                        news
                        header}
        }
        }

   
        
            }
          }
          
        }
`)




let x = (<LanguageContext.Provider value ={"hr"}>
            <MainLayout mainHeading = {d2.allCmbPagesJson.edges[0].node.home}>
            <About data={d2.allCmbPagesJson.edges[0].node}/>
        <Activities data={d2.allCmbPagesJson.edges[0].node}/>
        <History data={d2.allCmbPagesJson.edges[0].node}/>
        <News/>
            </MainLayout>
            </LanguageContext.Provider>
)

    return x
}



export function About(props) {
    const context = useContext(LanguageContext)

    let x =(
        <section className="vision paleOverlapBottom">
        <div className="halfSectionWrap">
        <ReactMarkdown>{props.data["home"][context].about}</ReactMarkdown>
              
        </div>
        
           <EventTable/>
        
    </section>
    )
return x
}
/*
export function EventTable(props) {

    let x =(

<dl className="eventTable">
                <dt>Upcoming Events</dt>
                    <a href="www.nothin.html"><dd>
                    <p>First Event Name</p>
                    <time>09.03.2022</time>
                    <p>Event Location</p>
                </dd></a>
                <a href="www.nothing.html"><dd>
                    <p>Second Event Name</p>
                    <time>09.03.2022</time>
                    <p>Event Location</p>
                </dd></a>
            </dl>   

    )

    return x
}
*/

export function History(props) {
    const context = useContext(LanguageContext)
    let x = (
<section className="history paleOverlapTop">
        <StaticImage className = {"historyImage"} src={'../images/bridge.jpeg'} alt=""/>
            <ReactMarkdown components={{'h1':'h2'}}>{props.data["home"][context].history}</ReactMarkdown>
    </section>
    )
return x
}

function nodeKeeper(props, index){

let x;
    props.index===index ? x =  <ReactMarkdown>{props.children[0]}</ReactMarkdown> : x="";

    return x

}

export function Activities(props) {
    const context = useContext(LanguageContext)

let title = <ReactMarkdown components={{h1:"h2"}} children = {props.data["home"][context].activities} allowElement={(node, index)=>[0, 2].indexOf(index)!==-1 ? true : false } includeElementIndex  />
    let text = <ReactMarkdown children = {props.data["home"][context].activities} allowElement={(node, index)=>[2,4].indexOf(index)!==-1 ? true : false } includeElementIndex  />
   // let text2 = <ReactMarkdown children = {props.data["home"]["en"].activities} allowedElements={"p"} includeElementIndex  components={{p:(({node, ...props})=>nodeKeeper(props, 1))}}/>
   let text2 = <ReactMarkdown children = {props.data["home"][context].activities} allowElement={(node, index)=>[4,6].indexOf(index)!==-1 ? true : false } includeElementIndex  />
   let text3 = <ReactMarkdown children = {props.data["home"][context].activities} allowElement={(node, index)=>[6,8].indexOf(index)!==-1 ? true : false } includeElementIndex  />


  let x=(  <section class="activities white">
      {title}
    
    <div className="activityFlagWrap">
    <div className="activityFlags">
    {text}
  
    </div>
    <div className="activityFlags">
       {text2}
    </div>
    <div className="activityFlags">
      {text3}
    </div>
</div>
</section>
    )
return x
}

